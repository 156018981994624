<style scoped>
ion-tab-button {
  --color: #b0bec5;
  --color-selected: #000;
  position: relative;
  overflow: visible;
}

ion-tab-button.tab-selected {
  color: var(--color-selected);
}

ion-tab-button ion-icon,
ion-tab-button ion-label {
  transition: color 0.3s ease;
  color: var(--color);
}

ion-tab-button.tab-selected ion-icon,
ion-tab-button.tab-selected ion-label {
  color: var(--color-selected);
}
</style>
<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet />
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          tab="balance"
          href="/tabs/balance"
          @click="selectedTab = 'balance'"
        >
          <ion-icon :icon="cashOutline" />
          <ion-label>Balance</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="transactions"
          href="/tabs/transactions"
          @click="selectedTab = 'transactions'"
        >
          <ion-icon :icon="listOutline" />
          <ion-label>Transactions</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="accounts"
          href="/tabs/accounts"
          @click="selectedTab = 'accounts'"
        >
          <ion-icon :icon="walletOutline" />
          <ion-label>Accounts</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue'
import { listOutline, cashOutline, walletOutline } from 'ionicons/icons'
import { ref } from 'vue'

const selectedTab = ref('balance')
</script>
